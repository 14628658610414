.mainSpace {
    max-width: 450px;
    margin: calc(var(--top-bar-height) + var(--mobile-bar-height)) auto 0;
}

.container {
    position: relative;
}

.mainImage {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    z-index: -1;
    display: block;
    position: relative;
}

.flooricon{
    position: absolute;
    background-color: Transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 0;
    max-width: 180px;
    padding: 0;

    filter: drop-shadow(7px 7px 5px #555);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flooricon:before{
    content:'';
    display:block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border-width:7px;
    border-style: solid;
    background-position:center;
    background-repeat:no-repeat;
    background-size: cover;
}

.flooricon:hover,.flooricon:focus-visible{
    filter: drop-shadow(0px 0px 20px #fff);
}

.flooricon:hover:before{box-shadow: inset 0px 0px 27px #fff;}

/*backgrounds*/
.firstFloor.iconOne:before{background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico1.jpg);}
.firstFloor.iconTwo:before{background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico2.jpg);}
.firstFloor.iconThree:before{background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico3.jpg);}
.firstFloor.iconFour:before{background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico4.jpg);}
.firstFloor.iconFive:before{background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico5.jpg);}

.secondFloor.iconOne:before{background-image: url(./../../../public/2113/icons/mb_ico_flr2_ico1.jpg);}
.secondFloor.iconTwo:before{background-image: url(./../../../public/2113/icons/mb_ico_flr2_ico2.jpg);}
.secondFloor.iconThree:before{background-image: url(./../../../public/2113/icons/mb_ico_flr2_ico3.jpg);}
.secondFloor.iconFour:before{background-image: url(./../../../public/2113/icons/mb_ico_flr2_ico4.jpg);}

/*borders*/
.firstFloor.iconOne:before{border-color:#FF9528;}
.firstFloor.iconTwo:before{border-color:#386FA4;}
.firstFloor.iconThree:before{border-color:#62941B;}
.firstFloor.iconFour:before{border-color:#228076;}
.firstFloor.iconFive:before{border-color:#FF4F1F;}
.secondFloor.iconOne:before{border-color:#228076;}
.secondFloor.iconTwo:before{border-color:#386FA4;}
.secondFloor.iconThree:before{border-color:#C2192B;}
.secondFloor.iconFour:before{border-color:#62941B;}



.firstFloor.iconOne {
    top: 6%;
    left: 5%;
}

.firstFloor.iconTwo {
    top: 5%;
    right: 5%;
}

.firstFloor.iconThree {
    top: 32%;
    right: 20%;

}

.firstFloor.iconFour {
    top: 50%;
    left: 3%;

}

.firstFloor.iconFive {
    bottom: 5%;
    right: 5%;

}

.secondFloor.iconOne {
    top: 6%;
    left: 12%;

}

.secondFloor.iconTwo {
    top: 23%;
    right: 4%;

}

.secondFloor.iconThree {
    top: 40%;
    right: 52%;

}

.secondFloor.iconFour {
    bottom: 6%;
    left: 30%;

}

.firstFloor.linkIcon {
    height: auto;
    width: auto;
    max-height: 160px;
    border-radius: 50%;
    box-shadow: 0 5px 5px #0000003d;
}

.firstFloor.linkIconLarge {
    height: auto;
    width: auto;
    max-height: 160px;
    border-radius: 50%;
    box-shadow: 0 5px 5px #0000003d;
}

.secondFloor.linkIcon {
    height: auto;
    width: auto;
    max-height: 160px;
    border-radius: 50%;
    box-shadow: 0 5px 5px #0000003d;
}

.mapTexts {
    background-color: white;
    padding: 2px 12px;
    font-size: 1rem;
    text-align: center;
    border-radius: 20px;
    border-style: solid;
    border-width: 4px;
    font-weight: bold;
    box-shadow: 0 5px 5px #0000003d;
    width: fit-content;
    margin: auto;
}

@media only screen and (max-width: 360px) {
    .flooricon:before {
        height: 100px;
        width: 100px;
    }
}
