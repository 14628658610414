.header {
  padding-top: 2vh;
  padding-left: 1vw;
  padding-right: 1vw;
  /* space for the absolute positioned text search results */
  padding-bottom: 6vh;
  background-color: #464646;
  color: #FFF;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
              rgb(0 0 0 / 14%) 0px 4px 5px 0px,
              rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}
.isMobile .header{
  display:none;
  top:100px;
}
.header h2 {
  font-size: 2em;
  margin-bottom: 2vh;
}

.search {
  padding: 2px 4px;
  display: flex;
  align-items: center;
}

.searchResults {
  margin-top: 0.9vw !important;
  position: absolute;
}


.content {
  /* 100% - header height (paddings + font-size + margins + search) */
  height: calc(100% - (2vh + 6vh + 2vw + 2vh + 40px));
}

.gridWrapper {
  overflow: auto;
  padding: 1.5vw;
  background-color: #C7C7C7;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.gridWrapper>ul{
  list-style: none;
  padding-inline-start: 0; 
}

.content *::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 30%);
}
.content *:hover::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 50%);
}

.vignette {
  position: relative;
}
.vignette:after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(197,197,197,0) 0%, rgba(79,79,79,0.5) 100%);
}
.cardMedia {
  object-fit: none !important;
  background-color: #969696;
}
.card {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  
}
.card:not(.kioskDisabled):hover {
  transform: scale(1.02);
  box-shadow:
    0px 8px 8px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.card, .cardActionArea {
  height: 100%;
}

.content .card:not(.kioskDisabled) a:hover,.content .card:not(.kioskDisabled) a:focus{
  text-decoration:underline;
}

.cardContent {
  height: calc(100% - 140px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardContentText {
  line-height: 1.25 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  /* make sure that the text don't go past 2 lines */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.kioskDisabled {
  color: #fff !important;
  box-shadow: none !important;
}
.kioskDisabled .cardContent {
  background-color: #000 !important;
}

.gridItem{
  padding:5px;
}
