@import url('https://fonts.googleapis.com/icon?family=Material+Icons&family=Lato:wght@400;700&display=swap');

:root {
    --csps-purple: #3F2A56;
    --csps-purple-mousover: #543C6E;
    --csps-grey: rgb(78, 91, 115);
    --csps-light-grey: #EDEFF1;
    --csps-medium-grey: #E5E7EA;
    --csps-coral: #DA797A;
    --top-bar-height: 50px;
    --mobile-bar-height: 54px;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
}

.background-black {
    background-color: #000000 !important;
}

.background-grey {
    background-color: #5b5b5b !important;
}

#root > *:not(header) {
    transition: filter 0.5s;
}

#root[aria-hidden=true] > *:not(header) {
    filter: blur(4px);
    transition: filter 1s;
}

*::-webkit-scrollbar {
    width: .9rem;
}
*::-webkit-scrollbar-track {
    background-color: transparent;
}
*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: rgb(0 0 0 / 30%);
}
*:hover::-webkit-scrollbar-thumb {
    background-color: rgb(0 0 0 / 50%);
}

*:focus-visible {
    outline: #00a1ff auto 1px;
    outline-offset: 10px;
}

.Mui-focusVisible {
    outline: #00a1ff auto 1px !important;
    outline-offset: 0px !important;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

#non_accessible_button {
    border: none;
    background-color: transparent;
    font-family: 'Lato', sans-serif;
    color: inherit;
    font-size: 1.25rem;
    text-decoration: none;
    padding: 0;
}

.accessible {
    color: var(--csps-purple);
    background-color: white;
    border-radius: 50%;
    padding: 2px;
    border: none;
}

.accessibleNew {
    padding: 2px;
}

.info {
    color: #656565;
    width: 1em;
    height: 1em;
    font-size: 34px;
    cursor: pointer;
}

.invisible {
    clip: rect(1px,1px,1px,1px);
    height: 1px;
    margin: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
}

h2 {
    font-size: 2.25rem;
    margin-bottom: 3px;
}

p {
    font-size: 1.25rem;
}

h2, p {
    margin: 0;
}

li {
    font-size: 1.25rem;
}

a {
    color: inherit;
    font-size: 1.25rem;
    text-decoration: none;
}

header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
}


button {
    font-family: 'Lato', sans-serif;
}

figcaption {
    font-size: 0.625rem;
}

.backgroundImage {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
}

#accessibility_button_container{
    padding: 6px;
}

#accessibility_button{
    padding: 5px;
}

#accessibility_button:hover{
    background-color: white;
}



body *.small{
    font-size:0.8em!important;
}
