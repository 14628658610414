.bypass h2{
    visibility:hidden;
    height:0px;
    width:0px;
    display:block;
}

.blocklist{
    position: fixed;
    display: flex;
    z-index: 4;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 0;
    margin:0;
    top: 0;
}

.blocklist a{
    display:block;
    overflow:hidden;
    width:0px;
    height:0px;
    top: 0;
    background-color: white;
    color:black;
}

.blocklist a:focus{
    width:auto;
    height:auto;
    padding: 0.5rem;
}

.blocklist>li{
    list-style:none;
}
