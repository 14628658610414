.container {
    display: flex;
    flex-direction: column;
    margin-top: var(50px);
}

.kioskContainer {
    display: flex;
    align-items: center;
}
