.SVGContainer {
    height: calc(100vh - var(--top-bar-height));
    /* For overflow vertical issue with Firefox */
    overflow: hidden;
    padding-top: var(--top-bar-height);
}

.SVGContainer svg {
    height: auto;
    max-height: 100%;
    max-width: 100%;
}

.SVGContainer svg a:focus{
    outline:none;   
}

.SVGContainer svg a,.SVGContainer svg a:focus:not(:focus-visible){
    opacity:0;
}

.SVGContainer svg a:focus-visible,.SVGContainer svg a:hover {

    opacity:1!important;
}
