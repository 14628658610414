.webGLContainerESDC {
    height: 100%;
    width: 100%;
    border: none;
    display: flex;
}

.webGLContainer {
    height: calc(100vh - var(--top-bar-height));
    width: 100%;
    border: none;
    display: flex;
    margin-top: var(--top-bar-height);
}

.webGLContainerFullscreen {
    height: 100vh;
    width: 100%;
    border: none;
    display: flex;
}

.unityContainer {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}

.unityCanvas {
    height: 100%;
    width: 100%;
    background: #FFFFFF;
}

.unityLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    display: block;
}

@keyframes loaded {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
        visibility: hidden;
    }
}

.loaded {
    animation: loaded ease-in 0.25s 0.25s 1 forwards;
}

.unityLoadingBar {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
