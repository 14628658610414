.mapTitle{
  top: 0;
  left: 50%;
  position: absolute;
  z-index: 2;
  transform: translateX(-50%);
  background-color: white;
}

.mapTitle h3 {
  margin: 0.5rem 1rem;
}

.flooricon{
  position: absolute;
  background-color: Transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 0;
  padding: 0;

  filter: drop-shadow(0px 5px 5px #00000061);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flooricon:before{
  content:'';
  display:block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border-width:10px;
  border-style: solid;
  background-position:center;
  background-repeat:no-repeat;
  background-size: cover;
}

.flooricon:hover .hide {
  display: block;
}

/*backgrounds && borders*/
.firstFloor.iconOne:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico1.jpg);
  border-color:#FF9528;
}
.firstFloor.iconTwo:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico2.jpg);
  border-color:#386FA4;
}
.firstFloor.iconThree:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico3.jpg);
  border-color:#62941B;
}
.firstFloor.iconFour:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico4.jpg);
  border-color:#228076;
}
.firstFloor.iconFive:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr1_ico5.jpg);
  border-color:#FF4F1F;
}

.secondFloor.iconOne:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr2_ico5.jpg);
  border-color:#FF9528;
}
.secondFloor.iconTwo:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr2_ico1.jpg);
  border-color:#228076;
}
.secondFloor.iconThree:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr2_ico2.jpg);
  border-color:#386FA4;
}
.secondFloor.iconFour:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr2_ico3.jpg);
  border-color:#C2192B;
}
.secondFloor.iconFive:before{
  background-image: url(./../../../public/2113/icons/mb_ico_flr2_ico4.jpg);
  border-color:#62941B;
}

.firstFloor.iconOne {
  top: 6%;
  left: 5%;
}

.firstFloor.iconTwo {
  top: 50%;
  left: 18%;
}

.firstFloor.iconThree {
  top: 10%;
  left: 35%;
}

.firstFloor.iconFour {
  bottom: 5%;
  right: 35%;
}

.firstFloor.iconFive {
  bottom: 35%;
  right: 5%;
}

.secondFloor.iconOne {
  bottom: 20%;
  left: 3%;
}

.secondFloor.iconTwo {
  top: 10%;
  left: 36%;
}

.secondFloor.iconThree {
  top: 10%;
  right: 18%;
}

.secondFloor.iconFour {
  bottom: 6%;
  right: 34%;
}

.secondFloor.iconFive {
  bottom: 6%;
  right: 4%;
}

.firstFloor.linkIcon {
  height: auto;
  width: auto;
  max-height: 160px;
  border-radius: 50%;
  box-shadow: 0 5px 5px #0000003d;
}

.firstFloor.linkIconLarge {
  height: auto;
  width: auto;
  max-height: 160px;
  border-radius: 50%;
  box-shadow: 0 5px 5px #0000003d;
}

.secondFloor.linkIcon {
  height: auto;
  width: auto;
  max-height: 160px;
  border-radius: 50%;
  box-shadow: 0 5px 5px #0000003d;
}

.mapTexts {
  background-color: white;
  padding: 2px 12px;
  font-size: 1rem;
  text-align: center;
  border-radius: 20px;
  border-style: solid;
  border-width: 4px;
  font-weight: bold;
  box-shadow: 0 5px 5px #0000003d;
  width: fit-content;
  margin: auto;
}

.hide {
  display: none;
}

.description {
  position: absolute;
  top: 33%;
  left: -155px;
  width: 140px;
  height: 115px;
  line-height: 115px;
  background-color: white;
  border-radius: 20px;
  padding: 10px 85px 10px 10px;
  transform: translateY(-40%);
  z-index: -1;
}

.firstFloor.iconOne .description{
  left: 75px;
  padding: 10px 10px 10px 70px;
}

.iconThree .description{
  left: 75px;
  padding: 10px 10px 10px 70px;
}

.secondFloor.iconOne .description{
  left: 55%;
  padding: 10px 10px 10px 70px;
}

.secondFloor.iconTwo .description{
  left: 75px;
  padding: 10px 10px 10px 70px;
}

.description p{
  font-size: 0.8rem;
  text-align: right;
  vertical-align: middle;
  line-height: 1.2;
  display: inline-block;
}

.secondFloor.iconTwo .description p{
  text-align: left;
}
.iconThree .description p{
  text-align: left;
}
.iconOne .description p{
  text-align: left;
}

.backgroundMap{
  width: 100%
}

@media only screen and (max-height: 650px) {
  .backgroundMap{
    max-height: 320px;
  }

  .flooricon:before{
    width: 100px;
    height: 100px;
  }

  .description {
    position: absolute;
    top: 33%;
    left: -120px;
    width: 100px;
    height: 95px;
    line-height: 95px;
  }

  .description p{
    font-size: 0.6rem;
  }

  .firstFloor.iconOne .description{
    left: 60px;
    padding: 10px 10px 10px 70px;
  }

  .iconThree .description{
    left: 60px;
    padding: 10px 10px 10px 70px;
  }

  .secondFloor.iconOne .description{
    left: 65px;
    padding: 10px 10px 10px 70px;
  }

  .secondFloor.iconTwo .description{
    left: 60px;
    padding: 10px 10px 10px 70px;
  }

  .mapTitle h3 {
    font-size: 1rem;
    margin: 0.25rem 0.5rem;
  }

  .mapTexts {
    font-size: 0.8rem;
  }
}

