.modal {
    overflow: auto;
}

.videoInner {
    width: 100%;
    height: calc(100vh - 45px);
    display: flex;
    margin: auto;
    padding-top: 45px;
    max-width: 920px;
}

.videoInner:focus-visible{
    outline: none !important;
}

.videoWrapper {
    position: relative;
    margin: auto .9rem auto .9rem;
}

.plyrWrapper{
    margin-top: 45px;
}

.plyrWrapper div[tabindex]:focus>button{
    outline:5px dotted #00b3ff;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.closeButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    display: inline-block;
    overflow: hidden;
    border: none;
}

@media only screen and (orientation: landscape) and (max-height: 630px){
    .closeButton {
        top: 5px;
        right: -45px;
    }

    .plyrWrapper{
        margin-top: 0;
    }

    .videoInner {
        padding: 10px 150px;
        box-sizing: border-box;
        height: 98vh;
    }
}

@media only screen and (orientation: portrait) and (max-width: 450px){
  .videoInner {
      height: 98vh;
      padding: 0px;
  }
}

.transcript{
    color:white;
    background-color:#333;
    margin-top: 10px;
}

.transcript>summary{
    cursor: pointer;
    padding:0.5rem;
}
.transcript>summary:focus,.transcript>summary:hover{
    background-color:#555;
}

.transcriptText p{
    font-size: 1rem;
    line-height: 1em;
    margin: 1rem 0;
}

.transcriptText{
    padding: 0 1rem 1rem 1rem
}

.relatedVideos {
    background-color: #e1e4e7;
    padding: 10px;
    border-radius: 20px;
    margin-top: 10px;
    outline: 1px solid #808080;
}

.relatedVideos h3{
    margin: 10px 30px 20px 30px;
}

.relatedVideosContainer {
    display: flex;
    margin: 0 30px;
}

.relatedVideosContainer p{
    color: rgb(71, 71, 71);
    font-size: 1rem;
    display: inline-block;
    padding-right: 20px;
    margin-bottom: auto;
    margin-top: auto;
}

.relatedVideoButton{
    cursor: pointer;
    outline: 1px solid;
    position: relative;
    padding: 0;
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
    border-radius: 10px;
    height: 72px;
}

.relatedVideoThumbnail{
    width: 128px;
    border-radius:10px;
    height: 72px;
}
