.modalcontainer {
  overflow: auto;
  /* FLEX*/
  display: flex;
}

.modalbox {
  position: relative; /*absolute makes the bg stretch right*/
  color: white;
  /* FLEX */
  display: flex;
  flex-direction: column;
  align-self: center;
  /* SIZE */
  width: 60vw;
  border: 2px solid #000;

  /* Background */
  background-color: black;
  background-image: url("../../../assets/images/banner_bg.jpg");
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;

  margin: auto;
}
.modalbox:focus {
  outline-color: #402a58;
}
.modalbox h2 {
  position: relative;
  z-index: 0;
}

/* *************************************************
 * HEADER
 * ************************************************/
/* ---- top-right blob ---- */
.header:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  /* Background */
  background-image: url("../../../assets/images/banner_blob.png");
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
}
