.link{    
    text-decoration: underline;
    display: block;
    position: relative;
}

.link:hover, .link:focus{
    background-color:#e1e1e1;                   
}


button.link{
    background-color:#fff;
    width:100%;
    font-family: Lato, sans-serif;
    font-size: 1.25em;
    cursor: pointer;
    
}
