.spaceContainer {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
    margin: calc(var(--top-bar-height) + var(--mobile-bar-height)) auto 0;
}

.spaceContainer img {
    height: auto;
    width: auto;
    min-height: 1px;
    max-height: 100%;
    max-width: 100%;
    z-index: -1
}

.description {
    padding: 20px 20px 10px 20px
}

.videoList {
    padding: 0;
}

.videoList li{
    background: url('../../assets/icons/video_icon.png') no-repeat 20px center;
    padding: 15px 15px 15px 65px;
    list-style: none;
    padding: 15px 15px 15px 65px;
    margin: 0;
    vertical-align: middle;
}

.videoList li a {
    text-decoration: underline;
}

.linksList {
    list-style: none;
    padding: 0;
}

.linksList li {
    padding: 10px 20px;
}

.linksList li a {
    text-decoration: underline;
}

.leftBorder {
    border-left: 6px solid #7AA9C2;
    margin: 10px;
}

.spaceContainer button {
    background: none!important;
    border: none;
    color: inherit;
    font-size: 1.25rem;
    text-decoration: underline;
    cursor: pointer;
    text-align: left;
    padding: 0;
}

.defaultCursor {
    cursor: default !important;
}

.pointerCursor {
    cursor: pointer !important;
}
