#unity-progress-bar-empty {
    width: 141px;
    height: 18px;
    margin-top: 10px;
    background: url('../../assets/images/progress-bar-empty.png') no-repeat center;
}

#unity-progress-bar-full {
    width: 0%;
    height: 18px;
    margin-top: 10px;
    background: url('../../assets/images/progress-bar-full.png') no-repeat center;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: 25%;
    width: 100px;
    height: 100px;
    border: 8px solid #000000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
