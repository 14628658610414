.modal, .backdrop {
  height: calc(100vh - var(--top-bar-height));
  top: var(--top-bar-height) !important;
}

.backdrop {
  cursor: pointer;
}

.tablet {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  outline: none;
}

/* left and right hands */
.screen:before, .screen:after {
  content: '';
  width: 256px;
  height: 338px;
  position: absolute;
  bottom: -228px;
  z-index: 1;
  background-size: contain !important;
}
.screen:before {
  background: url('../../assets/images/left_hand.png') right bottom no-repeat;
  left: -255px;
}
.screen:after {
  background: url('../../assets/images/right_hand.png') left bottom no-repeat;
  right: -255px;
}


/* Large devices (desktops, 1000px and up) */
.screen {
  box-sizing: border-box;
  width: 72vw;
  height: 50vw;
  background-color: #000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 15px;
  box-shadow: 0 0 0 1px #FFF;
  border-style: solid;
  border-bottom-width: 4vh;
  border-top-width: 4vh;
  border-left-width: 5vw;
  border-right-width: 5vw;
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .screen:before, .screen:after {
    bottom: -17vh;
  }
  .screen {
    width: 70vw;
    height: 75vh;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .screen:before, .screen:after {
    bottom: -12vh;
  }
  .screen {
    width: 65vw;
    height: 85vh;
  }
}
