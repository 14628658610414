.kioskTitle{
    text-align: center;
    margin: 1em;
    font-size: 1.5em;
    font-weight: 400;
    text-transform:uppercase;
}
.mainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(var(--top-bar-height) + var(--mobile-bar-height));
}

.navList{
    max-width:1000px;
    min-width:50%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.navList h3 {
    margin-left: 1.6rem;
}

.bgImage{
    width: 100%;
    max-width: 1000px;
    min-height: 225px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    background-color:#eeeeee;
}

.warnBrowser{
    position:relative;
    display:block;
    margin:1em 0;
}
