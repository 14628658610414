

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 900px) {
  .container{
    height: calc(100vh);
  }
}

.itemSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img404 {
  width:100%;
}
