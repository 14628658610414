

.IconText {
    display: flex;
    position: relative;
    margin: 0 auto;
    min-height: 5vh;
    box-sizing: content-box;
    transition: all 200ms;
    flex-direction: row;
    align-items: flex-start;
    line-height:1.4em;
    padding-top: 1.25rem;
}

.IconText p {
    flex: 1;
    
}

.IconText i{
    content: '';
    position: relative;
    display: block;
    height: 4vh;
    width: 4vh;
    min-height: 2.5rem;
    min-width: 2.5rem;
    background-size: cover;
    margin: 0rem 1rem 1rem 1.6rem;
}

.IconText.center {
    align-items: center;
    
}
.IconText.center i{
    margin: 0rem 1rem 0rem 1.6rem;
}

.IconText span{
    margin-bottom:1.25rem;
    margin-right:1.25rem;
}
