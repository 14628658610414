.webGLContainer {
    position: relative;
    width: 100%;
    border: none;
    height: calc(100vh - var(--top-bar-height));
}

.unityContainer {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.unityCanvas {
    max-height: 100%;
    max-width: 100%;
}

.unityLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    display: block;
}

@keyframes loaded {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
        visibility: hidden;
    }
}

.loaded {
    animation: loaded ease-in 0.25s 0.25s 1 forwards;
}

.unityLoadingBar {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.unityLoadingBar {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
