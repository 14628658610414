.responsive {
    width: 85%;
    height: auto;
}

.fipContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fipResponsive {
    width: 100%;
    height: 100%;
    max-width: 400px;
    overflow-y: hidden;
}

.fipCanadaLogo {
    height: auto;
    max-height: 40px;
}
