.header{
    position:relative;
    height:100px;
}

.infoButton{
    width: 34px;
    height: 34px;
    padding: 16px;
}

.topBarMobile {
    display: flex;
    background-color: #E1E4E7;
    box-shadow: 0 1px 6px #00000094;
    max-height: var(--mobile-bar-height);
    top: var(--top-bar-height);
    position: fixed;
    width: 100%;
    z-index: 2;

}

.mobileLeft{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.mobileTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
}

.dynamicInfoButton{
    width: 34px;
    height: 34px;
    padding: 16px;
}

.mobileRight {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mobileRight button{
    margin-right:15px;
}

.mobileRight a {
    display: inline-block;
    color: #656565;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
}

.mobileRight a:hover,.mobileRight a:focus-visible{
    background-color: #cbcbcb;
    cursor: pointer;
}

.iconImage {
    max-height: 2.5rem;
    filter: drop-shadow(0 3px 2px #00000040);
}

