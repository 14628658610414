.topBar {
    display: flex;
    background-color: #000000;
    height: var(--top-bar-height);
    max-height: var(--top-bar-height);
    box-shadow: 0 1px 6px #00000094;
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
    align-items: center;
}
.isMobile h1{
    font-size: 0.8rem;
    line-height: 1.3em;
    white-space: normal;
}

.title {
    display: inline-block;
    color: white;
    margin: 0;
    padding-right: 24px;
    margin-left: 24px;
    line-height: 50px;
    cursor: default;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: Normal;
}

.right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
}

.languageToggle {
    line-height: var(--top-bar-height);
    width: 52px;
}

.languageToggle:focus-visible {
    outline: #00a1ff auto 1px;
    outline-offset: -2px;
}
